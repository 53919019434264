<template lang="pug">
#sourcing
  b-container.top-wrap-sourcing
    .text-center.mt-4
        h4.page-heading.text-primary {{ $t('sourcing.gobal_sourcing') }}
        b-img(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Sourcing_Medten_USA.ef48bab7.jpg" fluid )

    .text-dark.p-2
      p {{ $t('sourcing.we_are_priviledge') }}
      //- p If there is a medical part or product you would like us to source for you, please send us the details in the form below and we will do our best to provide a quote that will meet your needs.
      p {{ $t('sourcing.blue_link_prefix') }}
        a(href="#" @click="openModal('footer-inquiry-modal')")
          strong {{ $t('sourcing.blue_link') }}
        |  
        | {{ $t('sourcing.blue_link_postfix') }}

      //- b-form.sourcing-quote-form(@submit.stop.prevent="onSubmit" v-if="!sourcing_submitted")
      //-   b-form-group(label="*Name:" label-cols="12" label-cols-sm="3")
      //-       b-form-input(type="text" v-model="my.name" v-validate="'required|min:5'" :state="validateState('name')" name="name" data-vv-as="Name")
      //-       b-form-invalid-feedback Name is required. 

      //-   b-form-group(label="*Email:" label-cols="12" label-cols-sm="3")
      //-       b-form-input(type="text" v-model="my.email" v-validate="'required|email'" :state="validateState('email')" name="email" data-vv-as="Email")
      //-       b-form-invalid-feedback Required and must be a valid email address.

      //-   b-form-group(label="*Phone number:" label-cols="12" label-cols-sm="3")
      //-       b-form-input(type="text" v-model="my.phone"  v-validate="{ required: true, regex: phone_regex }" :state="validateState('phone')" name="phone" data-vv-as="Phone number")
      //-       b-form-invalid-feedback Required and must be a valid phone number.

      //-   b-form-group(label="*Company Name:" label-cols="12" label-cols-sm="3")
      //-       b-form-input(type="text" v-model="my.company"  v-validate="'required|min:3'" :state="validateState('company')" name="company" data-vv-as="Company Name")
      //-       b-form-invalid-feedback Company name is required.

      //-   b-form-group(label="*Description:" label-cols="12" label-cols-sm="3")
      //-       b-form-textarea(rows=3 cols=40 v-model="my.description"  v-validate="'required|min:3'" :state="validateState('description')" name="description" data-vv-as="Description")
      //-       b-form-invalid-feedback Description is required.

      //-   b-form-group(label="Current supplier cost (if applicable):" label-cols="12" label-cols-sm="3")
      //-       b-form-input(type="text" v-model="my.current_supplier_cost")

      //-   b-form-group(label="Yearly quantity needed:" label-cols="12" label-cols-sm="3")
      //-       b-form-input(type="text" v-model="my.yearly_quantity_needed" ) 
        
      //-   .text-center.mt-3.mt-md-5
      //-     b-button.text-white.pl-5.pr-5(type="submit" :disabled="$validator.invalid" variant="primary") Submit
      
      //- b-container(fluid v-if="sourcing_submitted").text-center.p-4.bg-light
      //-   h5 Thank you for submitting the form. We will reach out to you shortly.
  MissionPromiseStatement
  Footer
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'pinia';
import { useBaseStore } from '@/stores/base-st';
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
const INTAKE_WEBHOOK = `https://hook.us1.make.com/t5j443e08c3t94j839om715cq9c24c2h`;
export default {
  name: 'Sourcing',
  components: {
    MissionPromiseStatement,
    Footer
  },
  data(){
    return {
        in_progress: 0,
        phone_regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        my: {
            name: null,
            email: null,
            phone: null,
            company: null,
            description: null,
            current_supplier_cost: null,
            yearly_quantity_needed: null,
        },
        vmessages: [],
        sourcing_submitted: false,
    };
  },

  computed:{
    //- ...mapGetters(['sourcing_submitted']),
    busy(){ return this.in_progress > 0; },
   
  },

  methods: {
    //- ...mapMutations(['setSourcingSubmitted']),
    //- async onSubmit(){
    //-   let is_valid = await this.$validator.validateAll();
    //-   if (!is_valid) return; 
    //-   await axios.post(INTAKE_WEBHOOK, this.my);
    //-   //- this.setSourcingSubmitted(true);
    //- },

    openModal(id){
      this.$bvModal.show(id);
    },
    async onSubmit(){
      try{
        let is_valid = await this.$validator.validateAll();
        if (!is_valid) return; 
        let result = await axios.post(process.env.VUE_APP_SOURCING_QUOTE_URL, this.my);
        console.log('result**', result);
        this.sourcing_submitted = true;
        this.my = {
          name: null,
          email: null,
          phone: null,
          company: null,
          description: null,
          current_supplier_cost: null,
          yearly_quantity_needed: null,
        };
      } catch(err){
        console.error(err);
      } finally {
        this.cart_busy = false;
      }
    },
    validateState(ref) {
      if ( this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated) ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

  },
}
</script>

<style lang="scss">
#sourcing{
  .top-wrap-sourcing{
    max-width: 800px;
    .page-heading{
    }
    .sourcing-quote-form{
      .form-group{
        .col-form-label{
          font-weight: 500;
        }
        @media (max-width: 543px){
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
</style>