<template lang="pug">
#about
  b-container.top-wrap-about
    .text-center.mt-4
      h4.page-heading.text-primary Terms of Use

    .text-dark.p-2
      p Procedures and conditions for using the website: Anyone visiting this website or using it any manner accepts, without restrictions, to be bound by the conditions specified below. Anyone who does not accept these terms or does not intend to be bound by them should not consider to be authorized to access, use, or download material from this site.
      p Medten Inc. reserves the right to update or modify these conditions and the procedures for accessing its website at any time without prior notice. 
      p These are the regulations for using site content. Authorized activities: Access to the website and its content, as a registered or unregistered visitor, is only allowed for the purposes permitted by Medten Inc.  and as indicated on this website. Our site and the data it contains may be consulted or utilized for personal use only (such as for personal information, research, or study). Prohibited activities: The user may not utilize or cause third parties to utilize the website and the data it contains for commercial purposes. Therefore, any economic utilization or commercial use by any user is strictly prohibited, unless contractually agreed in advance in writing with Medten Inc. 
      p If violations of the website’s conditions of use are detected, Medten Inc. reserves the right to take any corrective measures without prior notice, including preventing the visitor/user from accessing the website and its services and removing any material placed there by said visitor/user, without assuming any direct or indirect responsibility. Medten Inc. commits to collaborating fully with all competent authorities, including in order to promptly identify those responsible for any breach of the law.
      p Connections to other websites: The Medten Inc. website may contain links to other websites belonging to third parties. Medten Inc. commits to requesting authorization in advance before linking to any third-party website. Users who decide to access other linked websites assume all risks that this decision entails. Any user who wishes to include a link to this website on their homepage must first  request authorization from Medten Inc. by submitting their request in writing to its offices in 1 Technology Dr. Suite B115 Irvin, CA 92618.Medten Inc. reserves the right to evaluate the request and grant authorization at its sole discretion; Medten Inc. further reserves the right to act pursuant to the law against any person who introduces links that have not been authorized in advance. So-called “deep linking” is prohibited: all links to this website should lead directly to the homepage of the website and must disclose that this website and its content are separate from the website containing the link; they must also disclose that Medten Inc. owns and operates this site, without any sharing of content from our site that created the link. Similarly, use of the “framing” technique is also prohibited.
      p Liability: Medten Inc.  (which for the purposes of this section should be understood to include its administrators, auditors, directors, employees, agents, and Medten Inc. consultants), nor any other Medten Inc. licensee may be held responsible for actions and/or damages that may be caused to the user and/or third parties from using this website and/or its content. Medten Inc. cannot be held liable for any actions and/or damage which may be caused by any steps it legitimately takes in order to address the aforementioned prohibited activities. Furthermore, Medten Inc. cannot be held liable for problems encountered by the user rising from interruptions, suspensions, delays, or malfunctions in providing the service resulting from the supply of electricity or telephone/telecommunication services, or other technical means by which the content is transmitted, and for which Medten Inc. is not responsible. Despite its consistent efforts to improve the services it offers, Medten Inc. cannot be held liable to third parties with regard to the accuracy or current status of the content of this website.
      p Minors: Our site is not intended for individuals under the age of 21 but does not contain content that is offensive or intended only for adult audiences.
      p Changes: We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
      p Contact us: For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by emailing to info@medten.com.

  MissionPromiseStatement
  Footer
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'pinia';
import { useBaseStore } from '@/stores/base-st';
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'About',
  components: {
    MissionPromiseStatement,
    Footer
  },
  data(){
    return {
    };
  },

  computed: {
    ...mapState(useBaseStore, ['message']),
  },
 
  created(){

  },

  mounted(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  
  watch:{

  },

  methods:{
    
  },
  
}
</script>

<style lang="scss" scoped>
#about{
  .top-wrap-about{
    .page-heading{
      
    }
  }
}
</style>